module.exports = {
  // https://stackoverflow.com/questions/44332126/turbolinks-enabletransitioncache-is-not-a-function
  hookUp: function() {
    var REFRESH_INTERVAL_IN_MILLIS = 1000
    var timerID = null

    maybeSetTimer = function() {
      if ($('.cd-needs-refresh').length > 0) {
        timerID = window.setTimeout(reload, REFRESH_INTERVAL_IN_MILLIS)
      }
    }

    reload = function() {
      $.get(window.location.toString()).done(function(data, textStatus, jqXHR){
        var container = $($.parseHTML(data)).find("#cd-submission");
        $("#cd-submission").replaceWith(container);
        maybeSetTimer()
      })
    }

    document.addEventListener('turbolinks:load', function() {
      maybeSetTimer()
    })

    document.addEventListener('turbolinks:before-cache turbolinks:before-render', function() {
      window.clearTimeout(timerID)
    })
  }
}