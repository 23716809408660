// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Load Bootstrap JS from node_modules/.
require("bootstrap")

// This makes it so that Webpack is aware of our SCSS. This compiles the CSS
// bundle into somewhere in packs/css and then we include that in our HTML using
// <%= stylesheet_pack_tag 'application', media: 'all', 'data-turbolinks-track': 'reload' %>.
require("../stylesheets/application.scss")


Turbolinks.setProgressBarDelay(200);

document.addEventListener("turbolinks:load", () => {
  // Required for Boostrap <= 4.5.0. I think this changed for Boostrap 5.0.
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  $('[data-toggle="dropdown"]').dropdown()

  if (window.MathJax !== undefined && MathJax.typesetPromise !== undefined) {
    // This might not be defined the first time turbolinks:load runs.
    MathJax.typesetPromise()
  }
})

// When Turbolinks renders a page from cache
// (https://github.com/turbolinks/turbolinks#understanding-caching), the
// expanded navbar on mobile seems to "flicker". This hides it before the cached
// page is rendered.
document.addEventListener("turbolinks:before-render", () => {
  $("#navbarNavCollapsible").collapse('hide');
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


require("./submissions").hookUp()